// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ModalStyles.css */

.table thead th {
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
  }
  
  .table tbody tr:nth-child(odd) {
    background-color: #f8f9fa; /* Light gray for odd rows */
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #ffffff; /* White for even rows */
  }
  
  .table tbody tr:hover {
    background-color: #e9ecef; /* Light gray for hover effect */
  }
  
  .table th, .table td {
    vertical-align: middle; /* Aligns content vertically in the center */
  }
  
  /* Optional: Add some padding for cells */
  .table td {
    padding: 15px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Adminpages/ModalStyles.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,yBAAyB,EAAE,4BAA4B;IACvD,YAAY;EACd;;EAEA;IACE,yBAAyB,EAAE,4BAA4B;EACzD;;EAEA;IACE,yBAAyB,EAAE,wBAAwB;EACrD;;EAEA;IACE,yBAAyB,EAAE,gCAAgC;EAC7D;;EAEA;IACE,sBAAsB,EAAE,4CAA4C;EACtE;;EAEA,yCAAyC;EACzC;IACE,aAAa;EACf","sourcesContent":["/* ModalStyles.css */\n\n.table thead th {\n    background-color: #007bff; /* Bootstrap primary color */\n    color: white;\n  }\n  \n  .table tbody tr:nth-child(odd) {\n    background-color: #f8f9fa; /* Light gray for odd rows */\n  }\n  \n  .table tbody tr:nth-child(even) {\n    background-color: #ffffff; /* White for even rows */\n  }\n  \n  .table tbody tr:hover {\n    background-color: #e9ecef; /* Light gray for hover effect */\n  }\n  \n  .table th, .table td {\n    vertical-align: middle; /* Aligns content vertically in the center */\n  }\n  \n  /* Optional: Add some padding for cells */\n  .table td {\n    padding: 15px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
