import React from 'react'

const DirectHiring = () => {
  return (
    <div>DirectHiring</div>

    
      // {
      //   step 1->register the candidate (direct mappinf assiged user id) with required main details
      //   step 2-> fetching with assiged user id from applicant referals tables
      //   step 3-> selcted and rejected . rejected? rejected at screening.
      //   step 4 -> selected ? hr form direct statsus updates
      // }
    
  )
}

export default DirectHiring