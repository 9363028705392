// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML: <div class="loader"></div> */
.loader {
    width: 65px;
    aspect-ratio: 1;
    position: relative;
  }
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    border-radius: 50px;
    box-shadow: 0 0 0 3px inset #E10174;
    animation: l4 2.5s infinite;
  }
  .loader:after {
    animation-delay: -1.25s;
  }
  @keyframes l4 {
    0% {
      inset: 0 35px 35px 0;
    }
    12.5% {
      inset: 0 35px 0 0;
    }
    25% {
      inset: 35px 35px 0 0;
    }
    37.5% {
      inset: 35px 0 0 0;
    }
    50% {
      inset: 35px 0 0 35px;
    }
    62.5% {
      inset: 0 0 0 35px;
    }
    75% {
      inset: 0 0 35px 35px;
    }
    87.5% {
      inset: 0 0 35px 0;
    }
    100% {
      inset: 0 35px 35px 0;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/loader.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;EACpB;EACA;;IAEE,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,mCAAmC;IACnC,2BAA2B;EAC7B;EACA;IACE,uBAAuB;EACzB;EACA;IACE;MACE,oBAAoB;IACtB;IACA;MACE,iBAAiB;IACnB;IACA;MACE,oBAAoB;IACtB;IACA;MACE,iBAAiB;IACnB;IACA;MACE,oBAAoB;IACtB;IACA;MACE,iBAAiB;IACnB;IACA;MACE,oBAAoB;IACtB;IACA;MACE,iBAAiB;IACnB;IACA;MACE,oBAAoB;IACtB;EACF","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\n.loader {\n    width: 65px;\n    aspect-ratio: 1;\n    position: relative;\n  }\n  .loader:before,\n  .loader:after {\n    content: \"\";\n    position: absolute;\n    border-radius: 50px;\n    box-shadow: 0 0 0 3px inset #E10174;\n    animation: l4 2.5s infinite;\n  }\n  .loader:after {\n    animation-delay: -1.25s;\n  }\n  @keyframes l4 {\n    0% {\n      inset: 0 35px 35px 0;\n    }\n    12.5% {\n      inset: 0 35px 0 0;\n    }\n    25% {\n      inset: 35px 35px 0 0;\n    }\n    37.5% {\n      inset: 35px 0 0 0;\n    }\n    50% {\n      inset: 35px 0 0 35px;\n    }\n    62.5% {\n      inset: 0 0 0 35px;\n    }\n    75% {\n      inset: 0 0 35px 35px;\n    }\n    87.5% {\n      inset: 0 0 35px 0;\n    }\n    100% {\n      inset: 0 35px 35px 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
